// Temp fix for admin download all fields until powersearch table customisation project is deployed
export const ADMIN_FIELDS = [
  'investigationId',
  'createdAt',
  'guaranteeDisposition',
  'recommendedDecisionDisposition',
  'claimStatus',
  'investigationReviewDisposition',
  'returnId',
  'cardHolderName',
  'recipientFullName',
  'userAccountEmail',
  'recipientConfirmationEmail',
  'billingAddress',
  'recipientAddress',
  'ipGeolocationFull',
  'userAccountPhone',
  'recipientConfirmationPhone',
  'shopperName',
  'productId',
  'discountCode',
  'orderTotalAmount',
  'shippingMethod',
  'cardBinCountry',
  'billingAddressProvinceCode',
  'avsResponseCode',
  'cvvResponseCode',
  'cardBin',
  'cardLastFourDigits',
  'deviceId',
  'browserIp',
  'ipGeolocationFull',
  'authorizationFailureReason',
  'authorizationGatewayStatus',
  'paymentGateway',
  'orderChannel',
  'teamId',
  'signifydScore',
  'orderId',
  'userAccountNumber',
  'isTestInvestigation',
  'accountHolderTaxId',
  'sellerId',
  'sellerParentEntity',
  'sellerName',
  'caseStatus',
  'caseType',
  'recommendedDecisionDisposition',
  'guaranteeRecommendedAction',
  'checkoutToken',
  'guaranteeSubmittedAt',
  'guaranteeReviewedAt',
  'caseUrl',
  'checkoutPolicy',
  'returnPolicies',
  'recordLocator',
  'leadPassengerName',
]
