import { FC, useEffect } from 'react'
import moment from 'moment-timezone'
import {
  AppAnalytics,
  ShadowUserBanner,
  AppConfigProvider,
  QAInfoPopover,
} from '@signifyd/components'
import { caseSearchTokens } from '@signifyd/sig-keys'
import { theme } from '@signifyd/ant'
import useGetUserData from 'core/hooks/useGetUserData'
import AppRoutes from './AppRoutes'

const AppContainer: FC = () => {
  const userData = useGetUserData()

  const { currentUser, userConfigs } = userData

  const userTimeZone = currentUser?.uiState?.timeZone?.name

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  return (
    <AppConfigProvider
      theme={theme}
      localization={{
        appName: 'case-search',
      }}
      user={currentUser}
      userConfigs={userConfigs}
    >
      <AppAnalytics
        enabled={process.env.BUILD_ENV === 'production'}
        user={currentUser}
        logRocketId={caseSearchTokens.logRocketId}
        heapAppId={caseSearchTokens.heapAppId}
      />
      <AppRoutes userData={userData} />
      <ShadowUserBanner currentUser={currentUser} />
      <QAInfoPopover />
    </AppConfigProvider>
  )
}

export default AppContainer
