import {
  DatePicker,
  RangePickerValue,
  i18nInstance,
} from '@signifyd/components'
import {
  getMaxFlexibleSearchIsoDate,
  getMinFlexibleSearchIsoDate,
} from '@signifyd/utils'
import { FC } from 'react'
import moment from 'moment-timezone'
import { LOOKBACK_LIMIT } from 'Constants'
import { UserData } from 'core/hooks/useGetUserData'
import { useTableContext } from 'pages/InvestigationSearchPage/containers/SearchContainer/TableProvider'
import useGetInvestigations from 'pages/InvestigationSearchPage/containers/SearchResultsTable/useGetInvestigations'
import { useTableQueryParams } from 'pages/InvestigationSearchPage/queries/useTableQueryParams'

interface Props {
  userData: UserData
}

const { RangePicker } = DatePicker

const DATE_FORMAT = 'M/D/YYYY'

const userConfig = {
  admin: {
    lookbackValue: 4,
    text: 'four',
  },
  customer: {
    lookbackValue: 2,
    text: 'two',
  },
}

export const getQuickDateRangeOptions = (): Array<{
  label: string
  value: [moment.Moment, moment.Moment]
}> => {
  return [
    {
      label: i18nInstance.t('searchBar.date.today'),
      value: [moment().startOf('day'), moment().endOf('day')],
    },
    {
      label: i18nInstance.t('searchBar.date.lastSevenDays'),
      value: [
        moment().subtract(7, 'days').startOf('day'),
        moment().endOf('day'),
      ],
    },
    {
      label: i18nInstance.t('searchBar.date.lastThirtyDays'),
      value: [
        moment().subtract(30, 'days').startOf('day'),
        moment().endOf('day'),
      ],
    },
  ]
}

const SearchDatePicker: FC<Props> = ({ userData }) => {
  const { query, setQuery } = useTableQueryParams()
  const { selectedRowKeys } = useTableContext()
  const { data } = useGetInvestigations()
  const { normalizedPurchaseCreatedAt } = query

  if (data?.totalResults === undefined) {
    return null
  }

  const { isAdmin } = userData
  const config = isAdmin ? userConfig.admin : userConfig.customer

  const onDateChange = (value: RangePickerValue): void => {
    if (!value) {
      return
    }

    const [min, max] = value

    if (!min || !max) {
      return
    }

    setQuery({
      normalizedPurchaseCreatedAt: {
        min: getMinFlexibleSearchIsoDate(min),
        max: getMaxFlexibleSearchIsoDate(max),
      },
    })
  }

  const isDateDisabled = (current: moment.Moment | null): boolean => {
    if (!current) {
      return false
    }

    return (
      current <
      moment().subtract(config.lookbackValue, LOOKBACK_LIMIT.MAX_VALUE_UNIT)
    )
  }

  if (!normalizedPurchaseCreatedAt) {
    return null
  }

  const from = moment(normalizedPurchaseCreatedAt.min).startOf('day')
  const to = moment(normalizedPurchaseCreatedAt.max).endOf('day')

  return (
    <RangePicker
      disabled={!!selectedRowKeys.length}
      value={[from, to]}
      format={DATE_FORMAT}
      onChange={onDateChange}
      disabledDate={isDateDisabled}
      presets={getQuickDateRangeOptions()}
      getPopupContainer={(triggerNode) => {
        return triggerNode.parentNode as HTMLElement
      }}
    />
  )
}

export default SearchDatePicker
